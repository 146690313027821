<template>
  <div class="demo">
    <h1>Enc Video 视频测试</h1>
    <span @click="handlePlay">播放</span>
    <span @click="handleStop">停止</span>
    <div class="player">
      <enc-video
        :serverUrl="serverUrl"
        :username="user.name"
        :userpassword="user.password"
        :deviceId="deviceId"
        :mode="mode"
        :poster="true"
        :date="dayValue.getTime()"
        :seekTime="seekTime"
        :enablePtz="true"
	      :enableRecord="true"
        @stop="handleStop"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Demo",
  components: {},
  data() {
    return {
      user : { name: 'admin', password: 'Vplatform_123' },
      serverUrl: 'http://123.58.243.19:81',
      deviceId: '13100000001310190006',
      mode: 'realplay',
      dayValue: new Date(),
      seekTime: ''
    };
  },
  mounted() {},
  methods: {
    handlePlay: function () {
      this.deviceId = '13100000001310190006'
    },
    handleStop: function () {
      this.deviceId = ''
    }
  },
};
</script>

<style lang="less" scoped>
@Width: 192rem;
.player {
  width: 720 / @Width;
  height: 405 / @Width;
  background-color: black;  
  border-radius: 10px;

}
enc-video{
	--my-video-bg: black;
  width: 100%;
  border-radius: 0;
}
span {
  display: inline-block;
  margin: 10px 10px 10px 0;
}
</style>
